'use strict';

angular.module('appApp')
  .directive('primoCbTextfield', function () {
    return {
      templateUrl: 'components/primo-cb-textfield/primo-cb-textfield.html',
      restrict: 'EA',
      scope: { model: '=model' },
      //link: function (scope, element, attrs) { }
      link: function () { }
    };
  });
